<template>
  <div>
    <div>
      <div class="dispflex pt-3" style="flex-wrap: inherit">
        <div class="dk_icon" style="width: 10%">
          <span class="has-float-label" style="margin-top: 4%">
            <select
              @change="changeTerm(selectTerm,null)"
              class="form-control form-input"
              id="deptlistid"
              v-model="selectTerm"
            >
              <option :value="null">-- Select--</option>
              <option
                v-for="(item, index) in terms"
                :key="index"
                :value="item._id"
              >
                {{ item.name }}
              </option>
            </select>
            <label for="clslistid">Select Term </label>
          </span>
        </div>
        <div class="dk_icon" style="margin-right: 78%; width: 10%">
          <span class="has-float-label" style="margin-top: 4%">
            <select @change="getCurriculumInfoWithSubject(selectSubject)" class="form-control form-input" id="deptlistid" v-model="selectSubject">
              <option :value="null">-- Select--</option>
              <option
                v-for="(item, index) in subjectAllList"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
            <label for="clslistid">Select Subject </label>
          </span>
        </div>
      </div>
    
      <div class=" tbllist">
        <div class="crtfeeplancont" style="padding: 0 13px">
          <div
            class="widjethdr dispflex"
            style="align-items: baseline; padding: 10px 0"
          ></div>
          <div class="restable">
            <div class="resrow resheader">
              <div class="cell">Subject</div>
              <div class="cell">Topics</div>
              <div class="cell">Chapter No</div>
              <div class="cell">No of Classes</div>
              <div class="cell">Status</div>
              <div class="cell">From Date</div>
              <div class="cell">To Date</div>
              <div class="cell">Action</div>
            </div>
            <div
              class="resrow"
              v-for="(data, index) in curriculumInfo"
              :key="index"
            >
              <div class="cell">{{ data.subject && data.subject.subjectName }}</div>
              <div class="cell">
                {{ data.topic  }}
              </div>
              <div class="cell">
                {{ data.chapterNo  }}
              </div>
              <div class="cell">
                {{ data.noOfClass }}
              </div>
              <div class="cell">
                {{ data.status  }}
              </div>
              <div class="cell">
                {{
                  data.startDate ? data.startDate.substring(0, 10) : ''
                }}
              </div>
              <div class="cell">
                {{
                  data.toDate ? data.toDate.substring(0, 10) : ''
                }}
              </div>
              <div class="cell">
               
                <button
                  class="btn round orange tooltipt"
                  @click.prevent="viewUpdateCurriculumPopUp(index)"
                >
                  <i class="far fa-eye"></i
                  ><span class="tooltiptext">View</span>
                </button>
              </div>
            </div>
          </div>
          <div
            class="widjet"
            v-if="curriculumInfo.length == 0"
            style="height: 198px; box-shadow: none"
          >
            <div style="padding-top: 92px">
              <span style="margin-left: 42%">No Data Found</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="addCurriculumPopUp" style="width: 100%">
      <button type="button" class="close"  @click.prevent="closeCurriculumPopUp">×</button>

      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2> {{ curriculumCreateData._id ? 'Edit Curriculum' : 'Add Curriculum'}}</h2>
        <br />
        <div>
          <form id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    disabled
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="selectTerm"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in termList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <label for="clsnameid"
                    >Term <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <select disabled
                    
                    class="form-control form-input"
                    id="subjectId"
                    v-model="selectSubject"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in teacherAllowSubjects"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.subjectName }}
                    </option>
                  </select>
                  <label for="clsnameid"
                    >Select Subject <span class="required">*</span></label
                  >
                </span>
              </div>
              <!-- </div> -->
              <!-- <div class="form-row"> -->
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.topic"
                    type="text"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Topic <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.noOfClass"
                    type="text"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >No of classes <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    id="subjectId"
                    v-model="curriculumCreateData.status"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in statusList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label for="clsnameid"
                    >Status <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.startDate"
                    type="date"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Start Date <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.toDate"
                    type="date"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >End Date<span class="required">*</span></label
                  >
                </span>
              </div>
            
            </div>
          
          
            <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon"></div> -->

              <div class="text-center dk_iconsml">
                <button
                  :disabled="!curriculumCreateData.topic || !curriculumCreateData.noOfClass || !curriculumCreateData.status || !curriculumCreateData.startDate || !curriculumCreateData.toDate"
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click="saveTermSubjects"
                >
                    <span>Save</span>
                </button>
                <button
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                  @click="closeCurriculumPopUp"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <b-modal id="viewCurriculumPopUp" style="width: 50%">
      <button type="button" class="close"  @click.prevent="closeViewCurriculumPopUp">×</button>

      <div class="contact-form">
        <h2>View</h2>
        <br />
      
        
          <form id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <select
                    disabled
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="selectTerm"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in terms"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <label for="clsnameid"
                    >Term <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3" v-if="curriculumCreateData.subject && curriculumCreateData.subject.subjectName">
                <span class="has-float-label">
                  <input
                    disabled
                    v-model="curriculumCreateData.subject.subjectName"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Subject <span class="required">*</span></label
                  >
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                  disabled
                    v-model="curriculumCreateData.topic"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Topic <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                  disabled
                    v-model="curriculumCreateData.noOfClass"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >No Of Classes <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.status"
                    disabled
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Status <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.startDate"
                    type="date"
                    disabled
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Start Date <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="curriculumCreateData.toDate"
                    type="date"
                    disabled
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >To Date <span class="required">*</span></label
                  >
                </span>
              </div>
            </div>
            <div class="form-row" style="background-color: beige;
    margin-top: 2%;
    margin-bottom: 3%;
    width: 100%;" >
               Materials
              </div>
            <div class="form-row" style="width: 100%;" v-for="(doc, indexDoc) in documentList" :key="indexDoc">
               <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    v-model="doc.name"
                    disabled
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">File Name</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    v-model="doc.date"
                    disabled
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">File Upload Date</label>
                </span>
              </div>
               <div class="form-group input-group col-md-2 mt-3 ">
                <button class="btn btn-doc btn-sm" style="background-color: darkseagreen;"  @click.prevent="preViewDocument(doc.url.Location)">
                              View
                            </button>
                           
                            <!-- //key -->
                 <!-- <button class="btn round red tooltipt"   style="height:1.8rem"><i class="far fa-trash-alt"></i><span class="tooltiptext">Delete</span></button> -->
               
              </div>
              </div>

            <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >

              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                  @click="closeViewCurriculumPopUp"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      
    </b-modal>
    <b-modal id="sortingTopicPopUp" size="sm" style="width: 50%;" >
      <button type="button" class="close"  @click.prevent="closeviewSortingPopUp">×</button>

      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Sort By Topic</h2>
        <br />
      
          <app-dragabble
               class="flex flex-wrap dropzone min-height-50"
               style="cursor: grab;"
               :list="curriculumInfo"
               :group="{ name: 'regions', put: true }"
            >

            <div class="row mt-4 countsection" style="padding-left: 15%;padding-right: 18%;" v-for="(component, cmpIndex) in curriculumInfo" :key="cmpIndex">
            <div class="col-lg-12">
              <div class="countsect p-2 ml-2 mr-2">
            {{  cmpIndex + 1 }}.   {{ component.topic }}
              </div>
            </div>
          </div>

            </app-dragabble>

          
           <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <div class="dk_icon">

              </div>

              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="updateSortingTopic"
                >
                    <span>Save</span>
                </button>
                <button
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                  @click="closeviewSortingPopUp"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
        </div>
      
    </b-modal>
    <b-modal
      id="materialPopup"
      style="width: 100%"
     
    >
    <button type="button" class="close"  @click.prevent="closePoup">×</button>

      <div class="contact-form">
        <h2>Materials</h2>
        <br />
        <div>
          <form
            id="crtfeeplans"
            accept-charset="utf-8"
          >
          <div class="row">
         
          <div class="col-lg-12">
              
               <div class="form-row" >
                           <input
                            type="file"
                            id="importExportID"
                            ref="attachDoc"
                            name="attachDoc"
                            accept="application/pdf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/jpg, image/png"
                            @change="updateFile('attachDoc')"
                           />
              </div>
              <div class="form-row" style="width: 100%;" v-for="(doc, indexDoc) in documentList" :key="indexDoc">
               <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    v-model="doc.name"
                    disabled
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">File Name</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    v-model="doc.date"
                    disabled
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">File Upload Date</label>
                </span>
              </div>
               <div class="form-group input-group col-md-2 mt-3 ">
                <button class="btn btn-doc btn-sm" style="background-color: darkseagreen;"  @click.prevent="preViewDocument(doc.url.Location)">
                              View
                            </button>
                            <button class="btn btn-doc btn-sm" style="margin-left: 7px;background-color: indianred;" @click.prevent="removeDocument(indexDoc,doc.url.key)">
                              Delete
                            </button>
                            <!-- //key -->
                 <!-- <button class="btn round red tooltipt"   style="height:1.8rem"><i class="far fa-trash-alt"></i><span class="tooltiptext">Delete</span></button> -->
               
              </div>
              </div>
          </div>
          </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <div class="text-center dk_iconsml">
            
             
              <button
                @click.prevent="closePoup"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import ViService from "@/services/ViService";
import secureUI from "../../../utils/secureUI";
import draggable from "vuedraggable";

export default {
  name: "teacher",
  props: {
    classId: {
      type: String,
    },
    classRoom: {
      type: String,
    },
    terms: {
      type: Array,
    },
    accountId: {
      type: String,
    },
    academicYearId: {
      type: String,
    }
  },
  data() {
    return {
      selectSubject: null,
      selectTerm: null,
      termList: [],
      documentList: [],
      assignmentView: "List",
      showSetupTimeTable: false,
      curriculumInfo: [],
      subjectAllList: [],
      isCreateNew: false,
      teacherAllowSubjects: [],
      subjectList: [],
      addMaterial: {

      },
      curriculumCreateData: {
        subject: null,
        topic: "",
        noOfClass: null,
        status: "",
        startDate: null,
        toDate: null,
      },
      addTopicDetails: {
        topic: "",
        noOfClass: null,
        status: "",
        startDate: null,
        toDate: null,
      },
      statusList: ["Started", "In-Progress", "Completed"],
    };
  },
  created() {
  //  this.getSubjectList();
  this.initComponent();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },


  methods: {
    async initComponent(){
      await this.initComponentView()
    },
    initComponentView(){
     
     if (this.terms.length > 0) {
            if (this.terms.length == 1) {
            this.selectTerm = this.terms[0]._id;
            this.getCurriculumInfo(this.selectTerm,null)
            //this.getCurriculumInfo(this.terms[0]._id,null);

            // var startDate = new Date(this.terms[0].startDate);
            // var endDate = new Date(this.terms[0].endDate);

            // this.minDate = this.dateFormat(startDate)
        
            // this.maxDate =  this.dateFormat(endDate)
            }else{
            let currentDate = new Date()

            let checkData = this.terms.filter( x => (currentDate >= new Date(x.startDate)) && (currentDate <= new Date(x.endDate)))
            if(checkData.length > 0){

              this.selectTerm = checkData[0]._id;
              this.getCurriculumInfo(checkData[0]._id,null);

              var startDate = new Date(checkData[0].startDate);
              var endDate = new Date(checkData[0].endDate);

              // this.minDate = this.dateFormat(startDate)
          
              // this.maxDate =  this.dateFormat(endDate)

            }else{
              let checkfutureDates = this.terms.filter( x => (new Date(x.startDate) > currentDate))
             if(checkfutureDates.length > 0){
              this.selectTerm = checkfutureDates[0]._id;
              this.getCurriculumInfo(checkfutureDates[0]._id,null);

              // var startDate = new Date(checkfutureDates[0].startDate);
              // var endDate = new Date(checkfutureDates[0].endDate);

              // this.minDate = this.dateFormat(startDate)
          
              // this.maxDate =  this.dateFormat(endDate)
             }else{
              this.selectTerm = this.terms[0]._id;
              this.getCurriculumInfo(this.terms[0]._id,null);

              // var startDate = new Date(this.termList[0].startDate);
              // var endDate = new Date(this.termList[0].endDate);

              // this.minDate = this.dateFormat(startDate)
          
              // this.maxDate =  this.dateFormat(endDate)
            }
            }
           
            }
          }
    },
    preViewDocument(path) {
      window.open(path, "_blank");
    },
    addTopics() {
      this.curriculumCreateData.topics.push({
        topic: this.addTopicDetails.topic,
        noOfClass: this.addTopicDetails.noOfClass,
        startDate: this.addTopicDetails.startDate,
        toDate: this.addTopicDetails.toDate,
        status: this.addTopicDetails.status,
      });

      this.addTopicDetails = {
        topic: "",
        noOfClass: null,
        status: "",
        startDate: null,
        toDate: null,
      };
    },

    // addSub(data) {
    //   this.curriculumCreateData.subjects.push({
    //     _id: null,
    //     name: data,
    //   });
    // },

    async saveTermSubjects() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let allowSave = true;
        // if (this.curriculumInfo.length > 0) {
        //   let details = this.curriculumInfo.find((x) => x.subject == this.curriculumCreateData.subject);
        //   if (details && !this.curriculumCreateData._id) {
        //     allowSave = false;
        //   } else {
        //     allowSave = true;
        //   }
        // } else {
        //   allowSave = true;
        // }
        if (allowSave) {
          this.curriculumCreateData["class"] = this.classId;
          this.curriculumCreateData["classroom"] = this.classRoom;
          this.curriculumCreateData["term"] = this.selectTerm;
          if(this.curriculumCreateData._id){
            this.curriculumCreateData.subject = this.curriculumCreateData.subject
          }else{
            this.curriculumCreateData["subject"] = this.selectSubject
          }
         
          
      

          const response = await ViService.viXPost(
            "/academic/curriculumn/save",
            this.curriculumCreateData,
            userData.token
          );

          if (response.isSuccess) {
            // const resultData = secureUI.secureGet(response.data);
            this.$toasted.success(response.message);
            this.closeCurriculumPopUp();
            this.getCurriculumInfo(this.selectTerm,this.selectSubject);
          } else {
            this.$toasted.error(response.message);
          }
        // } else {
        //   this.$toasted.error("Subject Already Created");
         }
      }
    },
    async removeDocument(index,key){
        let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
          let newList = []

        //  this.candidateEditDetails.eligibilityCriteriaDocuments.forEach((x, i) => {
        //  if(i == index){
        //    x.key = '',
        //    x.path = ''
        //  }
        //  newList.push(x)
				// });

        const response = await ViService.viXPut(
          `/document/removeTeacherMaterialDocument/${key}`,
          newList,
          userData.token
        );
        
        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.documentList.splice(index,1);

          this.updateTeacherMaterials()
        // this.candidateEditDetails.eligibilityCriteriaDocuments = newList
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      
      
     
    },
    async updateTeacherMaterials() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let allowSave = true;
        // if (this.curriculumInfo.length > 0) {
        //   let details = this.curriculumInfo.find((x) => x.subject == this.curriculumCreateData.subject);
        //   if (details && !this.curriculumCreateData._id) {
        //     allowSave = false;
        //   } else {
        //     allowSave = true;
        //   }
        // } else {
        //   allowSave = true;
        // }
        if (allowSave) {
          // this.curriculumCreateData["class"] = this.classId;
          // this.curriculumCreateData["classroom"] = this.classRoom;
          // this.curriculumCreateData["term"] = this.selectTerm;
          // this.curriculumCreateData["subject"] = this.selectSubject
          
          this.addMaterial.documents = this.documentList

         
          const response = await ViService.viXPost(
            "/academic/curriculumn/updateTeaherMaterial",
            this.addMaterial,
            userData.token
          );

          if (response.isSuccess) {
           // this.addMaterial = {}
            // const resultData = secureUI.secureGet(response.data);
           // this.$toasted.success(response.message);
           // this.closeCurriculumPopUp();
           // this.getCurriculumInfo(this.selectTerm,this.selectSubject);
          } else {
            this.$toasted.error(response.message);
          }
        // } else {
        //   this.$toasted.error("Subject Already Created");
         }
      }
    },
    async updateFile(docType) {
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const docFileDetails = this.$refs[docType].files[0]
        
        if(docFileDetails){

          const fd = new FormData();

          fd.append(
            "doc",
            docFileDetails,
            docFileDetails.name,
            docFileDetails.actType
          );

          const response = await ViService.viPutOnly(
            `/document/uploadFile/teacherMaterial`,
            fd,
            userData.token
          );
          
          if(response.isSuccess){
            this.documentList.push({
              name: docFileDetails.name,
              url: response.value.url,
              date: new Date()
            })
           this.updateTeacherMaterials()

          }else{
            console.log("response",response)
          }

        }
        
        document.getElementById("importExportID").value=null; 

      }

    },
    async updateSortingTopic() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let allowSave = true;
       
        if (allowSave) {
       

          const response = await ViService.viXPost(
            "/academic/curriculumn/updateTopicSorting",
            this.curriculumInfo,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            this.closeviewSortingPopUp();
            this.getCurriculumInfo(this.selectTerm,this.selectSubject);
          } else {
            this.$toasted.error(response.message);
          }
       
         }
      }
    },
    changeTerm(id,subid){
       this.selectSubject = null
       this.getCurriculumInfo(id,subid)
    },

    async getCurriculumInfo(id,subId) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if(id){
        let requestBody = {
          class: this.classId,
          classroom: this.classRoom,
          term: id,
          academicYear: this.academicYearId,
          accountId: this.accountId

        };
        const response = await ViService.viXPost(
          "/academic/studentView/curriculumn_info",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.curriculumInfo = resultData;
          this.allcurriculumInfo = resultData

          if(this.curriculumInfo.length > 0){
           let subjects = this.curriculumInfo.map( x => x.subject.subjectName)
        
           const uniqueArray = subjects.filter((value, index, self) => {
              return self.indexOf(value) === index;
            });

            this.subjectAllList = uniqueArray

          }
          
          //this.curriculumInfo = this.curriculumInfo.sort(function (x, y) { return x.order - y.order;});
          // if (this.curriculumInfo.length == 0) {
          //   this.isCreateNew = true;
          // }
        } else {
          this.$toasted.error(response.message);
        }
       }else{
         this.curriculumInfo = []
         this.selectSubject = null
      }
      }
    },
    getCurriculumInfoWithSubject(id){
      this.curriculumInfo = []
      if(id){
        let dd =  this.allcurriculumInfo.filter( x => x.subject.subjectName == id)
       this.curriculumInfo = dd;
      }else{
      //  let dd =  this.allcurriculumInfo.filter( x => x.subject.subjectName == id)
       this.curriculumInfo = this.allcurriculumInfo;
      }
    //  let dd =  this.allcurriculumInfo.filter( x => x.subject.subjectName == id)
    //  this.curriculumInfo = dd;
    //   this.getCurriculumInfo(this.selectTerm,id)
    },
    getSubjectName(id) {
      if (id) {
        if (this.subjectList.length > 0) {
          let details = this.subjectList.filter((x) => x._id == id);
          if (details.length > 0) {
            return details[0].subjectName;
          } else {
            return "";
          }
        } else {
          return "";
        }
      }
    },
    async getSubjectList() {
      this.teacherAllowSubjects = []
      
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/class/getSubjectList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.subjectList = secureUI.secureGet(response.data);
          if(this.subjectList.length > 0){
            
            if(this.assignedSubjects.length > 0){
              const filteredItems = this.subjectList.filter(item => this.assignedSubjects.includes(item._id.toString()));

            this.teacherAllowSubjects = filteredItems
            }else{
              this.teacherAllowSubjects = this.subjectList
            }
          
          }
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    openUpdateCurriculumPopUp(index) {
      let details = this.curriculumInfo[index];
      
      this.curriculumCreateData.topic = details.topic
      this.curriculumCreateData.noOfClass = details.noOfClass
      this.curriculumCreateData.status = details.status
      this.curriculumCreateData.startDate = details.startDate.substring(0, 10);
      this.curriculumCreateData.toDate = details.toDate.substring(0, 10);
      this.curriculumCreateData.subject = details.subject;
     // this.curriculumCreateData.topics = details.topics;
      this.curriculumCreateData._id = details._id;

      this.$bvModal.show("addCurriculumPopUp");
    },
    viewUpdateCurriculumPopUp(index) {
      let details = this.curriculumInfo[index];

      this.curriculumCreateData.subject = details.subject;
      this.curriculumCreateData.topic = details.topic
      this.curriculumCreateData.noOfClass = details.noOfClass
      this.curriculumCreateData.status = details.status
      this.curriculumCreateData.startDate = details.startDate.substring(0, 10);
      this.curriculumCreateData.toDate = details.toDate.substring(0, 10);
    //  this.curriculumCreateData.topics = details.topics;
      this.curriculumCreateData._id = details._id;

       
      this.addMaterial = details;
      this.documentList = this.addMaterial.documents

      this.$bvModal.show("viewCurriculumPopUp");
    },
    viewSortingPopUp(index) {
      this.$bvModal.show("sortingTopicPopUp");
    },
    viewMaterialPopup(index) {
      let details = this.curriculumInfo[index];
      
      this.addMaterial = details;
      this.documentList = this.addMaterial.documents
      // this.curriculumCreateData.topics = details.topics;
     // this.curriculumCreateData._id = details._id;
      
      this.$bvModal.show("materialPopup");
    },
    closePoup() {
      this.getCurriculumInfo(this.selectTerm,this.selectSubject);
      this.$bvModal.hide("materialPopup");
    },
    
    
    closeviewSortingPopUp(){
      this.$bvModal.hide("sortingTopicPopUp");
    },

    

   
    async confirmDelete(id) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viDelete(
          `/academic/curriculumn/delete/${id}`,
          userData.token
        );

        if (response.isSuccess) {
          this.getCurriculumInfo(this.selectTerm,this.selectSubject);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    openAssignTeacherPopUp() {
      this.$bvModal.show("assignTeacherPopUp");
    },
    closeAssignTeacherPopUp() {
      this.$bvModal.hide("assignTeacherPopUp");
    },
    openResultPopUp() {
      this.$bvModal.show("resultPopUp");
    },
    closeResultPopUp() {
      this.$bvModal.hide("resultPopUp");
    },

    openClassPerformance() {
      this.$bvModal.show("classPerformancePopUp");
    },
    closeClassPerformancePopUp() {
      this.$bvModal.hide("classPerformancePopUp");
    },
    openCurriculumPopUp() {
      this.$bvModal.show("addCurriculumPopUp");
    },
    closeViewCurriculumPopUp() {
    //  (this.selectSubject = null),
        // (this.selectTerm = null)
        (this.curriculumCreateData = {
          subject: null,
          topics: [],
        });
      this.$bvModal.hide("viewCurriculumPopUp");
    },
    closeCurriculumPopUp() {
      this.$bvModal.hide("addCurriculumPopUp");
      //(this.selectSubject = null),
        // (this.selectTerm = null)
        // this.curriculumCreateData = {
        //   subject: null,
        //   topics: []
        // };
        this.curriculumCreateData._id = null,
        this.curriculumCreateData.topic = "",
        this.curriculumCreateData.noOfClass = null,
        this.curriculumCreateData.status = "",
        this.curriculumCreateData.startDate = null,
        this.curriculumCreateData.toDate = null
    },

    openCreateTimeTablePopUp() {
      this.$bvModal.show("createTimeTablePopUp");
    },
    closeCreateTimeTablePopUp() {
      this.$bvModal.hide("createTimeTablePopUp");
    },
    enableSetupTimeTable() {
      this.showSetupTimeTable = true;
    },
    closeSetupTimeTable() {
      this.showSetupTimeTable = false;
    },
  },
  components: {
    FullCalendar,
    'app-dragabble': draggable
  },
};
</script>
